<template>
  <div>
    <div class="pad-container">
      <el-form :rules="rules" :model="sign_form" ref="form">
        <p>签到奖励</p>
        <el-row class="pad-container mb-10">
          <el-form-item prop="points">
            签到奖励：每日签到固定奖励：送
            <el-input class="form-input-100 mlr-15" v-model="sign_form.points"></el-input>积分
          </el-form-item>
        </el-row>
        <el-row class="pad-container mb-10">
          <div>
            <div class="mb-10">
              递增奖励：
              <el-switch
                v-model="sign_form.isIncrease"
                class="mlr-15"
                @change="addPrizeType"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <el-popover
                placement="top-start"
                title="说明"
                width="400"
                trigger="hover"
                :content="prizeInfo"
              >
                <el-button slot="reference" plain size="small" icon="el-icon-question">说明</el-button>
              </el-popover>
            </div>
            <div class="ml-40 mb-10">
              <el-form-item prop="increasePoints">
                签到第二天起递增奖励
                <el-input
                  class="form-input-100 mlr-15"
                  v-model="sign_form.increasePoints"
                  :disabled="!sign_form.isIncrease"
                ></el-input>积分
              </el-form-item>
            </div>
          </div>
          <div v-show="sign_form.isIncrease">
            <div class="mb-10">
              连续签到额外奖励：指定天数奖励
              <el-switch
                v-model="sign_form.isSpecifyDays"
                class="mlr-15"
                @change="addPrizeType"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <el-popover
                placement="top-start"
                title="说明"
                width="400"
                trigger="hover"
                :content="signInfo"
              >
                <el-button slot="reference" plain size="small" icon="el-icon-question">说明</el-button>
              </el-popover>
            </div>
            <el-row v-show="sign_form.isSpecifyDays" ref>
              <div
                v-for="(item, idx) in sign_form.specifyRewards"
                :key="idx"
                class="pad-container mb-10"
              >
                <el-form-item
                  :key="idx"
                  class="prize-info"
                  :prop="'specifyRewards.' + idx + '.specifyDays'"
                  :rules="{ required: true, message: '连续签到天数不能为空', trigger: 'blur' }"
                >
                  连续签到
                  <el-input class="form-input-200 mlr-15" v-model="item.specifyDays">
                    <template slot="append">天</template>
                  </el-input>额外赠送以下奖励
                  <i
                    class="el-icon-delete-solid fs-22 ml-40 delete_icon"
                    @click="deleteRule(idx)"
                  ></i>
                </el-form-item>
                <el-form-item class="prize-info">
                  <div class="mb-10">
                    <el-radio v-model="item.type" :label="1" @change="changeType(idx)">奖品</el-radio>
                    <el-button
                      size="small"
                      class="ml-15"
                      type="primary"
                      v-if="item.type==1"
                      @click="choosePrize(idx)"
                    >{{item.complimentaryId?'重新选择':'添加'}}</el-button>
                    <div v-if="item.type==1 && item.prizeDetail.length" class="pad-container">
                      <el-form-item
                        :prop="'specifyRewards.' + idx + '.prizeDetail'"
                        :rules="{ required: true, message: '奖品不能为空', trigger: 'blur' }"
                      >
                        <el-table class="mt-24 member-table" :data="item.prizeDetail">
                          <template v-for="(item) in tableGoodsHeader">
                            <el-table-column :label="item.value"  :key="item.key" v-if='item.key !== "operations"'>
                              <template slot-scope="scope" >
                                <el-avatar
                                  v-if='item.key == "imgUrl"'
                                  :src="picsPath(scope.row.imgUrl)"
                                  shape="square"
                                  alt="加载失败"
                                  :key="scope.row.imgUrl"
                                ></el-avatar>
                                <template v-else>{{scope.row[item.key]}}</template>
                              </template>
                            </el-table-column>
                          </template>
                        </el-table>
                      </el-form-item>
                    </div>
                  </div>
                  <div>
                    <el-radio v-model="item.type" :label="2" @change="changeType(idx)">优惠券</el-radio>
                    <el-button
                      size="small"
                      class="ml-15"
                      type="primary"
                      v-if="item.type==2"
                      @click="chooseCoupon(idx)"
                    >{{item.couponId?'重新选择':'添加'}}</el-button>
                    <div v-if="item.type==2 && item.prizeDetail.length" class="pad-container">
                      <el-form-item
                        :prop="'specifyRewards.' + idx + '.prizeDetail'"
                        :rules="{ required: true, message: '优惠券不能为空', trigger: 'blur' }"
                      >
                        <el-table class="mt-24 member-table" :data="item.prizeDetail" v-if='item.prizeDetail.length'>
                          <template v-for="(item) in tableCouponHeader">
                          <el-table-column :label="item.value" :key="item.key" v-if='item.key !== "operations"'>
                              <template slot-scope="scope" >
                                <el-avatar
                                  v-if='item.key == "imgUrl"'
                                  :src="picsPath(scope.row.imgUrl)"
                                  shape="square"
                                  alt="加载失败"
                                  :key="scope.row.imgUrl"
                                ></el-avatar>
                                <template v-else-if="item.key == 'couponType'">
                                  {{
                                  scope.row.type == 0 ? "折扣券" : scope.row.type == 1 ?"代金券" :scope.row.type == 2 ? "兑换券": scope.row.type == 3 ?"停车券": ""
                                  }}
                                </template>
                                <template  v-else-if="item.key == 'coupon'">
                                  {{couponType[scope.row.coupon]}}
                                </template>
                                <template v-else-if="item.key == 'drawCondition'">
                                  {{scope.row.drawCondition == 1 ? "免费领取" : "限领"}}
                                </template>
                                <template v-else>{{scope.row[item.key]}}</template>
                              </template>
                          </el-table-column>
                        </template>
                        </el-table>
                      </el-form-item>
                    </div>
                  </div>

                  <div v-if="item.type==3" class="mt-20">
                    <el-form-item
                      :prop="'specifyRewards.' + idx + '.points'"
                      :rules="{ required: true, message: '积分不能为空', trigger: 'blur' }"
                    >
                      <el-radio v-model="item.type" :label="3">积分</el-radio>
                      <span v-show="item.type==3">
                        送
                        <el-input class="search-input" v-model="item.points"></el-input>积分
                      </span>
                    </el-form-item>
                  </div>
                  <div v-else class="mt-20">
                    <el-radio v-model="item.type" :label="3">积分</el-radio>
                    <span v-show="item.type==3">
                      送
                      <el-input class="search-input" v-model="item.points"></el-input>积分
                    </span>
                  </div>
                  <div class="c-9 mt-20">
                    弹框标语：
                    <el-input
                      maxlength="26"
                      show-word-limit
                      class="form-input"
                      placeholder="请输入弹框标语"
                      v-model="item.popupDetail"
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
              <el-button plain type="primary" class="form-button" @click="addRule">添加天数规则</el-button>
            </el-row>
          </div>
        </el-row>
        <el-row class="pad-container mb-10">
          <div class="mb-10">
            商品兑换有效期
            <el-popover
              placement="top-start"
              title="说明"
              width="400"
              trigger="hover"
              :content="validInfo"
            >
              <el-button
                slot="reference"
                plain
                size="small"
                icon="el-icon-question"
                class="mlr-15"
              >说明</el-button>
            </el-popover>
          </div>
          <el-form-item style="margin-bottom:0" prop="validDay">
            <div class="ml-40 mb-10">
              领取后当天生效，有效天数
              <el-input class="form-input-200 mlr-15" v-model="sign_form.validDay">
                <template slot="append">天</template>
              </el-input>
            </div>
          </el-form-item>
        </el-row>
        <el-row class="pad-container">
          <el-form>
            <el-form-item label="活动规则">
              <el-input
                class="form-input"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8}"
                maxlength="300"
                show-word-limit
                placeholder="请输入活动规则"
                v-model="sign_form.rulesDescription"
              ></el-input>
            </el-form-item>
            <el-form-item label="弹框标语">
              <el-input
                class="form-input"
                maxlength="26"
                show-word-limit
                placeholder="请输入弹框标语"
                v-model="sign_form.popupMsg"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <div class="flx-row ali-c js-fe mt-50">
          <router-link :to="{name:'Checkin_list'}">
            <el-button plain class="form-button">取消</el-button>
          </router-link>
          <el-button class="form-button ml-15" type="primary" @click="submit('form')">确认</el-button>
        </div>
      </el-form>

      <!-- 优惠券弹窗 :keyId="item.type==2?    @getTableList='getTableCouponList' 'couponId':'goodsId'"-->
    <DepartmentGoodsDialog
      ref="CouponDialog"
      :tableHeader="tableCouponHeader"
      @getSelectedList="getSelectedCouponList"
      :searchArr='searchArr'
      :keyId="'couponId'"
      :selected='false'
    ></DepartmentGoodsDialog>
    
    <!-- 商品弹窗  @getTableList='getTableGoodsList' -->
     <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableGoodsHeader"
      @getSelectedList="getSelectedGoodsList"
      :keyId="'goodsId'"
      :selected='false'
    ></DepartmentGoodsDialog>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import { getCouponList } from "@/api/maketing/coupons"
import { getProcudtList } from "@/api/online_department_store/commodity_manage"
import { lrSignInRulesEdit, getlrSignInRulesDetail } from "@/api/maketing/checkin"
export default {
  components: {
    Pagination,
    DepartmentGoodsDialog
  },
  data() {
    return {
      sign_form: {
        points: "",
        isIncrease: 1,
        increasePoints: "",
        increaseDays: "",
        isSpecifyDays: 1,
        validDay: "",
        popupMsg: "",
        rulesDescription: "",
        specifyRewards: [
          {
            type: 1,
            points: "",
            couponId: "",
            complimentaryId: "",
            specifyDays: "",
            popupDetail: "",
            prizeDetail: []
          }
        ]
      },
      rules: {
        increasePoints: [
          { required: true, message: "签到第二天起递增奖励", trigger: "blur" }
        ],
        points: [{ required: true, message: "积分不能为空", trigger: "blur" }],
        validDay: [
          { required: true, message: "商品兑换有效期不能为空", trigger: "blur" }
        ],
        title: [{ required: true }],
        act_date: [{ required: true }],
        act_time: [{ required: true }],
        act_goods: [{ required: true }],
        act_store: [{ required: true }],
        act_type_radio: [{ required: true }],
        act_limit: [{ required: true }],
        base_price: [{ required: true }]
      },
      ruleIndex: "",
      prizeInfo: `用户连续签到第二天起，开始递增奖励，若断签从头计算。`,
      signInfo: `用户到达指定的天数后,在该时间点,获得额外赠送奖励,若断签则从头计算。`,
      validInfo: `商品兑换有效期只针对实体商品，优惠券除外。
                  例如，小明连续签到7天得到一个商品A，连续签到30天得到一张
                  优惠券B，那么可设置商品A在线下兑换有效期，过期则无法兑换；
                  优惠券则以优惠券本身的有效期为主。`,
      tableGoodsHeader: [  // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode"
        },
        {
          value: "商品图片",
          key: "imgUrl"
        },
        {
          value: "商品名称",
          key: "goodsName"
        },
        {
          value: "一级分类",
          key: "firstClassifyName"
        },
        {
          value: "二级分类",
          key: "secondClassifyName"
        },
        {
          value: "实价",
          key: "price"
        },
        {
          value: "库存",
          key: "stock"
        },
        {
          value: "操作",
          key: "operations"
        }
      ],
      tableCouponHeader: [  // 优惠券列表表头
        {
          value: "优惠券名称",
          key: "name"
        },
        {
          value: "优惠券类型",
          key: "coupon"
        },
        {
          value: "卡券有效期",
          key: "validTime"
        },
         {
          value: "领取条件",
          key: "drawCondition"
        },
        {
          value: "库存数量",
          key: "stock"
        },
        {
          value: "每人限领",
          key: "limitAmount"
        },
        {
          value: "领取数量",
          key: "received"
        },
        {
          value: "操作",
          key: "operations"
        }
      ],
      searchArr:[
        {
          name: "searchKey",
          value: "",
          placeholder: "请输入关键字"
        },
        {
          name: "couponClass",
          value: "",
          placeholder: "请选择类型"
        }
      ],
      couponType: ["","通用劵", "商品劵","餐饮优惠券","折扣券", "代金券","停车券"],
    };
  },
  mounted() {
    this.getDiscipline();
  },
  methods: {
    getDiscipline() {
      getlrSignInRulesDetail().then(res => {
        // console.log("res",res.data.body)
        this.sign_form = res.data.body
        let rules = this.sign_form.specifyRewards
        rules.map(item => {
          if (item.type == 1) {
            item.prizeDetail = [item.shopGoodsInfo]
          } else if (item.type == 2) {
            item.prizeDetail = [item.shopCouponVo]
          }
        })
      })
    },
    //添加规则
    addRule(e) {
      let prize = {
        type: "",
        points: "",
        couponId: "",
        complimentaryId: "",
        specifyDays: "",
        popupDetail: "",
        prizeDetail: []
      };
      // console.log(this.sign_form.specifyRewards)
      this.sign_form.specifyRewards.push(prize);
    },
    //删除规则
    deleteRule(idx) {
      this.sign_form.specifyRewards.splice(idx, 1);
    },
    //打开奖项开关
    addPrizeType(val) {},
    //切换奖励类型
    changeType(idx) {
      console.log(idx)
      this.sign_form.specifyRewards[idx].prizeDetail = [];
      this.sign_form.specifyRewards[idx].complimentaryId = null;
      this.sign_form.specifyRewards[idx].couponId = null;
      
    },
    // 校验部分字段
    validateField(fieldsToValidate) {
      Promise.all(
        fieldsToValidate.map(item => {
          return new Promise(resolve => {
            this.$refs["form"].validateField(item, error => {
              resolve(error);
            });
          });
        })
      ).then(data => {
        data = data.filter(item => item);
        if (!data.length) {
          // console.log("通过验证");
          let sign_form = JSON.parse(JSON.stringify(this.sign_form));
          sign_form.specifyRewards = [];
          if (!sign_form.isIncrease) {
            sign_form.increasePoints = null;
          }
          this.handlePost(sign_form);
        } else {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning"
          });
          // console.log("验证不通过");
        }
      });
    },
    submit(formName) {
      let data = this.sign_form;
      if (!data.isIncrease) {
        // A false
        this.validateField(["points", "validDay"]);
      } else if (data.isIncrease == 1 && !data.isSpecifyDays) {
        // A true B false
        this.validateField(["points", "increasePoints", "validDay"]);
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            // console.log("submit!");
            this.handlePost(data);
          } else {
            // console.log("error submit!!");
            this.$message({
              message: "表单信息不完整（有误）",
              type: "warning"
            });
            return false;
          }
        });
      }
    },
    handlePost(data) {
      lrSignInRulesEdit(data).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
        }
      })
    },
    getSelectedGoodsList(data, index) { //获取已选商品
      // // console.log(data)
      let rule = this.sign_form.specifyRewards[this.ruleIndex];
      rule.complimentaryId = data.goodsId;
      rule.prizeDetail = [data];
    },
    getTableGoodsList(data){ //根据搜索条件改变列表
      getProcudtList({goodsStatus: 1, ...data}).then(res => {
         let data = this.$refs.GoodsDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
      });
    },
    choosePrize(idx) {  // 打开商品列表弹窗
      this.ruleIndex = idx;
      let data = this.$refs.GoodsDialog;
      data.act_form.list = [];
      data.selected_temp = [];
      data.showGoods = true;
      data.getGoods(false);
    },
    getSelectedCouponList(coupons, index) { //获取已勾选优惠券
    // console.log(coupons)
      let rule = this.sign_form.specifyRewards[this.ruleIndex];
      rule.couponId = coupons.couponId;
      rule.prizeDetail = [coupons];
    },
    getTableCouponList(data){ //获取优惠券列表
      let params = { ...data}
      params.status = params.couponStatus
      delete params.couponStatus
      getCouponList(params).then(res => {
         let data = this.$refs.CouponDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
      });
    },
    chooseCoupon(idx) {  // 打开优惠券列表弹窗
      this.ruleIndex = idx;
      let data = this.$refs.CouponDialog;
      data.act_form.list = [];
      data.selected_temp = [];
      data.showGoods = true;
      data.getGoods(true);
    },
  }
};
</script>
<style lang="scss" scoped>
.delete_icon:hover {
  color: #f56c6c;
  cursor: pointer;
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>